import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import Layout from '../components/Layout';
import SEO from '../components/SEO/SEO';

import './privacypolicy.scss';

const tocAnchorLinks = {
  1: 'commitment-text',
  2: 'accountability-text',
  3: 'limit-collection-text',
  4: 'identifying-text',
  5: 'consent-text',
  6: 'limit-using-text',
  7: 'retention-text',
  8: 'accuracy-text',
  9: 'safeguards-text',
  10: 'openness-container-text',
};

const createTableOfContentsList = () => {
  const items = [];
  for (let i = 0; i < Object.keys(tocAnchorLinks).length; i++) {
    items.push(
      <li key={i}>
        <a href={`#${tocAnchorLinks[i + 1]}`}>
          <FormattedMessage id={`pages.privacy-policy.toc-headers.header${i + 1}`} />
        </a>
      </li>,
    );
  }
  return <ol>{items}</ol>;
};

const PrivacyPolicy = injectIntl(({ intl }) => (
  <Layout>
    <div className="page-content-wrapper">
      <section className="page-content">
        <header>
          <h1>
            <FormattedMessage id="pages.privacy-policy.title" />
          </h1>
        </header>

        <section id="privacy-policy-content">
          <section id="about-this-policy">
            <h2>
              <FormattedMessage id="pages.privacy-policy.about.header" />
            </h2>
            <p>
              <FormattedMessage id="pages.privacy-policy.about.part1" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.about.part2" />
            </p>
          </section>

          <section id="privacy-policy-table-of-contents">
            <h2>
              <FormattedMessage id="pages.privacy-policy.table-of-contents" />
            </h2>
            {createTableOfContentsList()}
          </section>

          <section id="commitment-text">
            <h2>
              1. <FormattedMessage id="pages.privacy-policy.toc-headers.header1" />
            </h2>
            <p>
              <FormattedMessage
                id="pages.privacy-policy.privacy-commitment.part1"
                values={{
                  pipeda: (
                    <Link to={intl.formatMessage({ id: 'pages.privacy-policy.privacy-commitment.pipedaLink' })}>
                      <em>
                        <FormattedMessage id="pages.privacy-policy.privacy-commitment.pipeda" />
                      </em>
                    </Link>
                  ),
                }}
              />
            </p>
          </section>

          <section id="accountability-text">
            <h2>
              2. <FormattedMessage id="pages.privacy-policy.toc-headers.header2" />
            </h2>
            <p>
              <FormattedMessage id="pages.privacy-policy.accountability.part1" />
            </p>
          </section>

          <section id="limit-collection-text">
            <h2>
              3. <FormattedMessage id="pages.privacy-policy.toc-headers.header3" />
            </h2>
            <p>
              <FormattedMessage id="pages.privacy-policy.information-collected.part1" />
            </p>
            <h3>
              <FormattedMessage id="pages.privacy-policy.information-collected.connectingWithUs" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.information-collected.connectingWithUsContent" />
            </p>
            <h3>
              <FormattedMessage id="pages.privacy-policy.information-collected.informationAboutYou" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.information-collected.informationAboutYouContentPart1" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.information-collected.informationAboutYouContentPart2" />
            </p>
            <h3>
              <FormattedMessage id="pages.privacy-policy.information-collected.digitalOnlineInfo" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.information-collected.digitalOnlineInfoContent" />
            </p>
            <h3>
              <FormattedMessage id="pages.privacy-policy.information-collected.thirdParty" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.information-collected.thirdPartyContent" />
            </p>
            <h3>
              <FormattedMessage id="pages.privacy-policy.information-collected.communication" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.information-collected.communicationContent" />
            </p>
          </section>

          <section id="identifying-text">
            <h2>
              4. <FormattedMessage id="pages.privacy-policy.toc-headers.header4" />
            </h2>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.part1" />
            </p>

            <h3 id="contact">
              <FormattedMessage id="pages.privacy-policy.identifying.contactYouTitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.contactYou" />
            </p>
            <h3 id="authenticate">
              <FormattedMessage id="pages.privacy-policy.identifying.authenticateTitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.authenticate" />
            </p>
            <h3 id="assess">
              <FormattedMessage id="pages.privacy-policy.identifying.creditworthinessTitle" />
            </h3>
            <p>
              <FormattedMessage
                id="pages.privacy-policy.identifying.creditworthiness"
                values={{
                  limitingUseLinkText: (
                    <Link to="/privacypolicy/#limit-using-text">
                      <FormattedMessage id="pages.privacy-policy.identifying.limitingUseLinkText" />
                    </Link>
                  ),
                }}
              />
            </p>
            <h3 id="decision-making">
              <FormattedMessage id="pages.privacy-policy.identifying.decisionTitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.decision1" />
            </p>
            <p>
              <FormattedMessage
                id="pages.privacy-policy.identifying.decision2"
                values={{
                  privacyEmail: (
                    <a href="mailto:canadaprivacyoffice@capitalone.com">
                      <FormattedMessage id="pages.privacy-policy.identifying.privacyEmail" />
                    </a>
                  ),
                }}
              />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.decision3" />
              <br />
              <FormattedMessage id="pages.privacy-policy.identifying.decision4" />
              <br />
              <FormattedMessage id="pages.privacy-policy.identifying.decision5" />
            </p>
            <h3 id="service">
              <FormattedMessage id="pages.privacy-policy.identifying.serviceTitle" />
            </h3>
            <p>
              <FormattedMessage
                id="pages.privacy-policy.identifying.service"
                values={{
                  limitingUseLinkText: (
                    <Link to="/privacypolicy/#limit-using-text">
                      <FormattedMessage id="pages.privacy-policy.identifying.limitingUseLinkText" />
                    </Link>
                  ),
                }}
              />
            </p>
            <h3 id="improvements">
              <FormattedMessage id="pages.privacy-policy.identifying.improvementsTitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.improvements1" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.improvements2" />
            </p>
            <h3 id="business">
              <FormattedMessage id="pages.privacy-policy.identifying.businessTitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.business" />
            </p>
            <h3 id="fraud">
              <FormattedMessage id="pages.privacy-policy.identifying.preventFraudTitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.preventFraud" />
            </p>
            <h3 id="offers">
              <FormattedMessage id="pages.privacy-policy.identifying.offersTitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.offers1" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.offers2" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.offers3" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.offers4" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.offers5" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.offers6" />
            </p>
            <h3 id="report">
              <FormattedMessage id="pages.privacy-policy.identifying.reportTitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.identifying.report" />
            </p>
          </section>

          <section id="consent-text">
            <h2>
              5. <FormattedMessage id="pages.privacy-policy.toc-headers.header5" />
            </h2>
            <p>
              <FormattedMessage id="pages.privacy-policy.consent.part1" />
            </p>
            <h3>
              <FormattedMessage id="pages.privacy-policy.consent.updatingTitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.consent.updating" />
            </p>
            <h3 id="opt-out">
              <FormattedMessage id="pages.privacy-policy.consent.optOutTitle" />
            </h3>
            <p>
              <FormattedMessage
                id="pages.privacy-policy.consent.optOut1"
                values={{
                  cookies: (
                    <Link to="/privacypolicy/#oba">
                      <FormattedMessage id="pages.privacy-policy.consent.cookies" />
                    </Link>
                  ),
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="pages.privacy-policy.consent.optOut2"
                values={{
                  cookies: (
                    <Link to="/privacypolicy/#oba">
                      <FormattedMessage id="pages.privacy-policy.consent.cookies" />
                    </Link>
                  ),
                }}
              />
            </p>
            <p className="underline-text">
              <FormattedMessage id="pages.privacy-policy.consent.optOut3" />
            </p>
            <p>
              <FormattedMessage
                id="pages.privacy-policy.consent.optOut4"
                values={{
                  email: (
                    <Link to="/SSP/preferences/">
                      <FormattedMessage id="pages.privacy-policy.consent.email" />
                    </Link>
                  ),
                }}
              />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.consent.optOut5" />
            </p>
            <h3 id="oba">
              <FormattedMessage id="pages.privacy-policy.consent.obaTitle" />
            </h3>
            <p>
              <FormattedMessage
                id="pages.privacy-policy.consent.oba"
                values={{
                  adChoices: (
                    <Link
                      to={intl.formatMessage({
                        id: 'pages.privacy-policy.consent.adChoicesLink',
                      })}
                    >
                      <FormattedMessage id="pages.privacy-policy.consent.adChoices" />
                    </Link>
                  ),
                }}
              />
            </p>
            <h3>
              <FormattedMessage id="pages.privacy-policy.consent.trackingTitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.consent.tracking" />
            </p>
            <h3>
              <FormattedMessage id="pages.privacy-policy.consent.advertisingTitle" />
            </h3>
            <p>
              <FormattedMessage
                id="pages.privacy-policy.consent.advertising"
                values={{
                  limit: (
                    <strong>
                      <FormattedMessage id="pages.privacy-policy.consent.limit" />
                    </strong>
                  ),
                  interestedBasedAds: (
                    <strong>
                      <FormattedMessage id="pages.privacy-policy.consent.interestedBasedAds" />
                    </strong>
                  ),
                }}
              />
            </p>
          </section>

          <section id="limit-using-text">
            <h2>
              6. <FormattedMessage id="pages.privacy-policy.toc-headers.header6" />
            </h2>
            <p>
              <FormattedMessage id="pages.privacy-policy.limit-using.part1" />
            </p>
            <h3>
              <FormattedMessage id="pages.privacy-policy.limit-using.thirdPartiesTitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.limit-using.thirdParties1" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.limit-using.thirdParties2" />
            </p>

            <ol id="third-party-services" className="roman-numeral">
              <li id="agencies">
                <h4>
                  <FormattedMessage id="pages.privacy-policy.limit-using.creditReportTitle" />
                </h4>
                <p>
                  <FormattedMessage id="pages.privacy-policy.limit-using.creditReport1" />
                </p>
                <p>
                  <FormattedMessage
                    id="pages.privacy-policy.limit-using.creditReport2"
                    values={{
                      equifax: (
                        <Link
                          to={intl.formatMessage({
                            id: 'pages.privacy-policy.limit-using.equifaxLink',
                          })}
                        >
                          <FormattedMessage id="pages.privacy-policy.limit-using.equifax" />
                        </Link>
                      ),
                      transunion: (
                        <Link
                          to={intl.formatMessage({
                            id: 'pages.privacy-policy.limit-using.transunionLink',
                          })}
                        >
                          <FormattedMessage id="pages.privacy-policy.limit-using.transunion" />
                        </Link>
                      ),
                    }}
                  />
                </p>
              </li>
              <li id="manufacturers">
                <h4>
                  <FormattedMessage id="pages.privacy-policy.limit-using.manufacturesTitle" />
                </h4>
                <p>
                  <FormattedMessage id="pages.privacy-policy.limit-using.manufactures" />
                </p>
              </li>
              <li id="print">
                <h4>
                  <FormattedMessage id="pages.privacy-policy.limit-using.printTitle" />
                </h4>
                <p>
                  <FormattedMessage id="pages.privacy-policy.limit-using.print" />
                </p>
              </li>
              <li id="processing">
                <h4>
                  <FormattedMessage id="pages.privacy-policy.limit-using.processingTitle" />
                </h4>
                <p>
                  <FormattedMessage id="pages.privacy-policy.limit-using.processing" />
                </p>
              </li>
              <li id="collection">
                <h4>
                  <FormattedMessage id="pages.privacy-policy.limit-using.collectionTitle" />
                </h4>
                <p>
                  <FormattedMessage id="pages.privacy-policy.limit-using.collection" />
                </p>
              </li>
              <li id="fraud-detection">
                <h4>
                  <FormattedMessage id="pages.privacy-policy.limit-using.fraudTitle" />
                </h4>
                <p>
                  <FormattedMessage id="pages.privacy-policy.limit-using.fraud" />
                </p>
              </li>
              <li id="mastercard">
                <h4>
                  <FormattedMessage id="pages.privacy-policy.limit-using.mastercardTitle" />
                </h4>
                <p>
                  <FormattedMessage id="pages.privacy-policy.limit-using.mastercard" />
                </p>
              </li>
              <li id="call-centre">
                <h4>
                  <FormattedMessage id="pages.privacy-policy.limit-using.callCenterTitle" />
                </h4>
                <p>
                  <FormattedMessage id="pages.privacy-policy.limit-using.callCenter" />
                </p>
              </li>
              <li id="marketing">
                <h4>
                  <FormattedMessage id="pages.privacy-policy.limit-using.marketingTitle" />
                </h4>
                <p>
                  <FormattedMessage id="pages.privacy-policy.limit-using.marketing" />
                </p>
              </li>
              <li id="firms">
                <h4>
                  <FormattedMessage id="pages.privacy-policy.limit-using.lawFirmTitle" />
                </h4>
                <p>
                  <FormattedMessage id="pages.privacy-policy.limit-using.lawFirm" />
                </p>
              </li>
              <li id="regulators">
                <h4>
                  <FormattedMessage id="pages.privacy-policy.limit-using.regulatorsTitle" />
                </h4>
                <p>
                  <FormattedMessage id="pages.privacy-policy.limit-using.regulators" />
                </p>
              </li>
            </ol>
            <h3>
              <FormattedMessage id="pages.privacy-policy.limit-using.assigneesTitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.limit-using.assignees" />
            </p>

            <h3>
              <FormattedMessage id="pages.privacy-policy.limit-using.informationTitle" />
            </h3>
            <p>
              <FormattedMessage id="pages.privacy-policy.limit-using.information1" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.limit-using.information2" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.limit-using.information3" />
            </p>
          </section>

          <section id="retention-text">
            <h2>
              7. <FormattedMessage id="pages.privacy-policy.toc-headers.header7" />
            </h2>
            <p>
              <FormattedMessage id="pages.privacy-policy.retention.part1" />
            </p>
          </section>

          <section id="accuracy-text">
            <h2>
              8. <FormattedMessage id="pages.privacy-policy.toc-headers.header8" />
            </h2>
            <p>
              <FormattedMessage id="pages.privacy-policy.accuracy.part1" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.accuracy.part2" />
            </p>
          </section>

          <section id="safeguards-text">
            <h2>
              9. <FormattedMessage id="pages.privacy-policy.toc-headers.header9" />
            </h2>
            <p>
              <FormattedMessage id="pages.privacy-policy.safeguards.part1" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.safeguards.part2" />
            </p>
            <p>
              <FormattedMessage id="pages.privacy-policy.safeguards.part3" />
            </p>
          </section>

          <section id="openness-container-text">
            <h2>
              10. <FormattedMessage id="pages.privacy-policy.toc-headers.header10" />
            </h2>
            <p>
              <FormattedMessage id="pages.privacy-policy.openness.part1" />
            </p>
            <p>
              <strong>
                <FormattedMessage id="pages.privacy-policy.openness.part2" />
              </strong>
            </p>
            <p>
              <strong>
                <FormattedMessage id="pages.privacy-policy.openness.byEmail" />
              </strong>
              <br />
              <a href="mailto:canadaprivacyoffice@capitalone.com">
                <FormattedMessage id="pages.privacy-policy.openness.privacyEmail" />
              </a>
            </p>
            <div id="privacy-address">
              <p>
                <strong>
                  <FormattedMessage id="pages.privacy-policy.openness.byMail" />
                </strong>
                <br />
                <FormattedMessage id="pages.privacy-policy.openness.address-attn" />
                {intl.locale === 'en' && (
                  <>
                    <br />
                    <FormattedMessage id="pages.privacy-policy.openness.address-recipient" />
                  </>
                )}
                <br />
                <FormattedMessage id="pages.privacy-policy.openness.addressCapOne" />
                <br />
                <FormattedMessage id="pages.privacy-policy.openness.addressLine1" />
                <br />
                <FormattedMessage id="pages.privacy-policy.openness.addressLine2" />
              </p>
            </div>

            <p>
              <FormattedMessage id="pages.privacy-policy.openness.part3" />
            </p>

            <p id="customer-service">
              <FormattedMessage id="pages.privacy-policy.openness.part4" />
              <br />
              <FormattedMessage
                id="pages.privacy-policy.openness.part5"
                values={{
                  representative: (
                    <strong>
                      <FormattedMessage id="pages.privacy-policy.openness.representative" />
                    </strong>
                  ),
                }}
              />
            </p>
          </section>
        </section>
      </section>
    </div>
  </Layout>
));

export default PrivacyPolicy;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.privacy-policy.page-title"
    descriptionKey="pages.privacy-policy.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
